import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function LookupResults(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="lookup_view lookup_view_results">
        <div className="lookup_view_head">
          <div className="container">
            <h2>Providing Access to On-Chain Royalties</h2>
            <div className="lookup_bar">
              <div className="input-group primary_inp_div">
                <input
                  type="text"
                  className="form-control primary_inp"
                  placeholder=" Search By Token Address "
                />
                <input
                  type="text"
                  className="form-control primary_inp lookup_small_inp"
                  placeholder=" ETH Value "
                />
                <button className="input-group-text" id="basic-addon2">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="lookup_view_body" name="faq">
          <div className="container">
            <h2 className="mb-4">Token Details</h2>
            <div className="token_details_content">
              <div>
                <span>Address</span>
                <span>0x0014C537130241DD2E7B9e8058812244a357c918</span>
              </div>
              <div>
                <span>Royalty Spec</span>
                <span>EIP2981</span>
              </div>
              <div>
                <span>ID</span>
                <span>1</span>
              </div>
              <div>
                <span>Etherscan</span>
                <span>
                  <a
                    target="_blank"
                    href="https://goerli.etherscan.io/token/0x0014C537130241DD2E7B9e8058812244a357c918?a=1"
                  >
                    https://goerli.etherscan.io/token/0x0014C537130241DD2E7B9e8058812244a357c918?a=1
                  </a>
                </span>
              </div>
            </div>

            <h2 className="mb-4">Royalties</h2>
            <table class="token_royalties_content table">
              <thead>
                <tr>
                  <th>Payable Address</th>
                  <th>Share (%)</th>
                  <th>Value (ETH)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                     
                      target="_blank"
                      href="https://goerli.etherscan.io/address/0x0000000000000000000000000000000000000000"
                    >
                      0x0000000000000000000000000000000000000000
                    </a>
                  </td>
                  <td>0</td>
                  <td>0.0</td>
                </tr>
              </tbody>
            </table>            
          </div>
        </div>
      </div>
    </div>
  );
}
