import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Web3 from 'web3';
import Royalityabi from "../ABI/Royality.json"
import config from "../Config/config.js"
import { useParams, useNavigate } from "react-router-dom";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}




export default function Configure(props) {
  const navigate = useNavigate();

  var { address } = useParams();


  // console.log(address, "result");
  const [useradd, setuseradd] = useState()
  const [error, seterror] = useState(undefined)
  const [tokenadd, settokenadd] = useState(address != undefined ? address : "")

  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    console.log(accounts);
    setuseradd(accounts[0])
  };

  const approvecontract = async () => {
    console.log(tokenadd, "ll");
    if (tokenadd == "") {
      seterror("Field Required")
      setTimeout(() => {
        seterror(undefined)
      }, 3000);
      return false
    }


    const web3 = new Web3(window.ethereum);

    const contract = new web3.eth.Contract(
      Royalityabi,
      config.singlesmartContract
    );
    console.log(contract, "contract");

    await contract.methods.getRoyaltyInfo(tokenadd).call({ 
      from: useradd,
    })
      // console.log(data,"data");
      .then((result) => {
        console.log(result, "result");
        // configure-results/:address

        // [0] royality ststuse //[1] isMutable ststuse 
        if (!result[1]) {
          navigate(`/configure-results/${tokenadd}`)
        } else {
          seterror("Royality is already updated to is contract")
        }

        setTimeout(() => {
          seterror(undefined)
        }, 3000);
      })


  }



  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="lookup_view">
        <div className="lookup_view_head">
          <div className="container">
            <h2>Token Address</h2>
            <div className="lookup_bar">
              <div className="input-group primary_inp_div">
                <input
                  type="text"
                  className="form-control primary_inp"
                  value={address}
                  placeholder="Enter Token Address"
                  onChange={(e) => settokenadd(e.target.value)}
                />
                <button className="input-group-text" id="basic-addon2" onClick={approvecontract}>
                  <i class="bi bi-arrow-right"></i>
                </button>
              </div>
              <p style={{ color: "red" }} >{error}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
