let key = {};
let Envname = "local"

if (Envname === "live") {
    // const API_URL = 'https://mob.queenbots.com';//DEMO
    // key = {
    //     baseUrl: `${API_URL}`,
    //     singlesmartContract: "0xd1576F8A6895E17Ae4525061FDF6F430D3AfA3B8",   //live
    //     network: 4,
    // };
}
else if (Envname === "demo") {
    // console.log("Set Production Config")
    const API_URL = 'https://dexbotapi.wealwin.com';//DEMO
    key = {
        baseUrl: `${API_URL}`,
        singlesmartContract: "0x01DE3b6A108239AF9eFE0b3A46558038eFa5b741",   //demo
        network: 97,
        message: "connect Binance Test Network"
    };

} else {

    const API_URL = 'http://localhost';
    key = {
        baseUrl: `${API_URL}:3040`,
        singlesmartContract: "0x01DE3b6A108239AF9eFE0b3A46558038eFa5b741",   //local
        network: 97,
        message: "connect Binance Test Network"
    };
}


export default key;