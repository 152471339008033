import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import Lookup from './pages/lookup';
import LookupResults from './pages/lookup-results';
import Configure from './pages/configure';
import ConfigureResults from './pages/configure-results';
import FAQ from './pages/faq';

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Lookup /> },
    { path: "lookup", element: <Lookup /> },
    { path: "lookup-results", element: <LookupResults /> },
    { path: "configure", element: <Configure /> },
    { path: "configure/:address", element: <Configure /> },
    { path: "configure-results", element: <ConfigureResults /> },
    { path: "configure-results/:address", element: <ConfigureResults /> },

    { path: "faq", element: <FAQ /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <App />
    </Router>
  );
};

export default AppWrapper;