import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";

import Web3 from 'web3';
import Royalityabi from "../ABI/Royality.json"
import config from "../Config/config.js"
import { useParams, useNavigate } from "react-router-dom";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ConfigureResults(props) {

  var { address } = useParams();

  const [Recipientaddress, setRecipientaddress] = useState("")
  const [amount, setamount] = useState("")
  const [status, setstatus] = useState("")
  const [useradd, setuseradd] = useState()
  const [error, seterror] = useState()

  console.log(error, "error");
  const funonchange = (e) => {
    // if (e.target.name == "amount") {
    //   if (e.target.value >= 1 && e.target.value <= 10) {
    //     // seterror("value must be (1-10)")
    //     seterror("")
    //     return true
    //   } else {
    //     seterror("value must be (1-10)")
    //     return false
    //   }
    // }
    seterror("")
    if (e.target.name == "addess") {
      const { value } = e.target
      setRecipientaddress(value)

    } else if (e.target.name == "amount") {
      const { value } = e.target
      if (value >= 1 && value <= 10) {
        setamount(value)
      } else {
        seterror("value must be (1-10)")
        return false
      }
    } else if (e.target.name == "status") {
      const { value } = e.target
      setstatus(value)
    }
  }
  useEffect(() => {
    acc();
  }, []);

  const acc = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    console.log(accounts, "accounts");
    setuseradd(accounts[0])
  };


  // useEffect(() => {
  //   setTimeout(() => {
  //     seterror("")
  //   }, 3000);
  // }, [error])

  const onsubmitroyalty = async () => {
    const web3 = new Web3(window.ethereum);
    // console.log(Recipientaddress, "useradd");
    console.log(Recipientaddress, amount, status, "123");

    if (Recipientaddress == undefined || Recipientaddress == "") {
      seterror("address required")
      return false
    } else if (status == undefined || status == "") {
      seterror("status required")

      return false
    } else if (amount == undefined || amount == "") {
      seterror("amount required")
      return false
    }

    if (amount >= 1 && amount <= 10) {
      // seterror("value must be (1-10)")
      // return false
    } else {
      seterror("value must be (1-10)")
      return false
    }

    seterror("")

    const contract = new web3.eth.Contract(
      Royalityabi,
      config.singlesmartContract
    );
    console.log(contract, "contract");

    function convert(n) {

      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
        : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    }
    var amt = convert(amount);
    const statusdata = status == "true" ? true : false

    var amount23 = amount
    var amt = amount23.toString();
    amt = convert(amt);

    console.log(address, Recipientaddress, amt, statusdata);
    await contract.methods.setRoyalty(address, Recipientaddress, amt, statusdata).send({
      from: useradd,
    })
      .then((result) => {
        console.log(result, "result");

      })
  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="configure_view">
        <div className="configure_view_inner">
          <div className="container">
            <div>
              <h2>Token Contract Default Royalty</h2>
              <p>Set a default policy for all tokens on this contract.</p>
              <p>
                Example: 10% = 1000{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Basis_point"
                  target="_blank"
                >
                  Basis Points (BPS)
                </a>
                .
              </p>
              <div className="lookup_bar">
                <div className="input-group primary_inp_div">
                  <input
                    type="text"
                    className="form-control primary_inp"
                    placeholder="Enter Recipient Address "
                    name="addess"
                    onChange={funonchange}
                  />

                  <select
                    type="text"
                    className="form-control primary_inp lookup_small_inp"
                    placeholder="Royalty Basis Points"
                    name="status"
                    onChange={funonchange}
                  ><option value="0">Royalty Status</option><option value="true">True</option>  <option value="false">False</option>  </select>
                  <input
                    type="text"
                    min="1" max="5"
                    className="form-control primary_inp lookup_small_inp"
                    placeholder="Royalty Basis Points"
                    name="amount"
                    onChange={funonchange}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    BPS
                  </span>
                </div>

              </div>
              <p style={{ color: "red" }} >{error}</p>


              <button className="primary_btn" onClick={onsubmitroyalty}>Save Default Royalty </button>
            </div>
            {/* <div className="mt-5">
              <h2>Per Token Royalties</h2>
              <p>Fetch and Configure Overrides by Token ID.</p>
              <p>Example: 10% = 1000 Basis Points.</p>
              <div className="lookup_bar">
                <div className="input-group primary_inp_div">
                  <input
                    type="text"
                    className="form-control primary_inp"
                    placeholder=" Fetch Token ID "
                  />                  
                  <button className="input-group-text" id="basic-addon2">
                    <i class="bi bi-arrow-right"></i>
                </button>
                </div>
              </div>              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}


