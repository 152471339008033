import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Lookup(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="lookup_view">
        <div className="lookup_view_head">
          <div className="container">
            <h2>Providing Access to On-Chain Royalties</h2>
            <div className="lookup_bar">
              <div className="input-group primary_inp_div">
                <input
                  type="text"
                  className="form-control primary_inp"
                  placeholder=" Search By Token Address "
                />
                <input
                  type="text"
                  className="form-control primary_inp lookup_small_inp"
                  placeholder=" ETH Value "
                />
                <button className="input-group-text" id="basic-addon2">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="lookup_view_body" name="faq">
          <div className="container">
            <h2 className="mb-4">Frequently Asked Questions</h2>
            <div class="accordion" id="faq">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How do on-chain Royalties work?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#faq"
                >
                  <div class="accordion-body">
                    <p>
                      Whenever an NFT sale occurs in a marketplace, it is up to
                      the marketplace to hold back part of the proceeds of the
                      sale to distribute to the original content creator. But
                      how does the marketplace know how much to hold back and
                      where to send the royalties to? That's where on-chain
                      Royalties come in.
                    </p>
                    <p>
                      On-chain royalties are royalty configurations that are
                      baked into the original token contract. They provide a way
                      for marketplaces to ask those contracts what the
                      appropriate royalty amount is and who the royalty
                      recipient should be for any token in that contract.
                    </p>
                    <p>
                      There are a number of token specs out there, including
                      EIP2981, SuperRare, Rarible and Manifold. While they all
                      do similar things (i.e. provide a royalty amount and
                      recipient address per token), they have slightly different
                      implementations. This makes it difficult for marketplaces
                      to adhere to all the royalty specs, and is problematic for
                      older tokens to add on-chain royalty configurations (i.e.
                      tokens that may have launched on OpenSea with royalties
                      set up off-chain).
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What is the Royalty Registry?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#faq"
                >
                  <div class="accordion-body">
                    <p>The goal of the Royalty Registry is twofold:</p>
                    <ul>
                      <li>
                        Make it easy for marketplaces to use the appropriate
                        on-chain royalty configurations
                      </li>
                      <li>
                        Make it possible for contracts that did not originally
                        support on-chain royalties to add them
                      </li>
                    </ul>
                    <p>
                      The Royalty Registry is composed of two parts: the Royalty
                      Registry and the Royalty Engine. The code can be found
                      here:
                      https://github.com/manifoldxyz/royalty-registry-solidity
                    </p>
                    <p>
                      The Royalty Registry is an on chain contract that is
                      responsible for storing Royalty configuration overrides.
                      The address for the registry is royaltyregistry.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Who built the Royalty Registry?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#faq"
                >
                  <div class="accordion-body">
                    <p>
                      Manifold.xyz built the Royalty Registry in collaboration
                      with Foundation, Nifty Gateway, OpenSea, Rarible and
                      SuperRare. We all believe that it’s important to ensure
                      creators get their fair share of every sale.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Who built the Royalty Registry?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#faq"
                >
                  <div class="accordion-body">
                    <p>
                      All the code is opens sourced and free to for anyone to
                      explore and contribute. The github repositories are:
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    For Creators
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#faq"
                >
                  <div class="accordion-body">
                    <p>
                      If your token contract already supports EIP2981 or is a
                      Manifold, Rarible or Zora based contract, you’re all good
                      to go! If you have a token created from Foundation,
                      Rarible or SuperRare, they are supported and you can
                      manage your royalties on their respective websites.
                    </p>
                    <p>
                      If none of the above apply, not to worry! Simply click
                      'Configure' at the top of this page to deploy your own
                      on-chain royalty configuration for your old token
                      contracts. Creators whose token contract was provided by
                      another platform may want to contact the platform or
                      contract owner for support with this operation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
