import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import $ from "jquery";

export default function Navbar(props) {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    $(document).ready(function () {
      $(".main_navbar ul.navbar-nav li a").click(function () {
        $(".navbar-toggler").trigger("click");
      });
    });
  }

  return (
    <>
      <nav className="navbar navbar-expand-md main_navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            RoyaltyRegistry
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/lookup">
                  Lookup
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/configure">
                  Configure
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/faq">
                  Faq
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="faq"
                  spy={true}
                  smooth={true}
                  duration={250}
                  color="transparent"
                  className="nav-link"
                >
                  Faq
                </Link> 
              </li> */}
            </ul>
            <ul className="navbar-nav mb-2 mb-lg-0 navbar_right">
              {/* <li><a href="#" className="nav-link">Mainnet</a></li>
              <li><a href="#" className="nav-link">Ropsten</a></li> */}
              <li><a href="#" className="nav-link active">Goerli</a></li>
            </ul>
            <div className="header_network_address">
              {/* <div>
                <span>Registry:</span>
                <a target="_blank" href="https://goerli.etherscan.io/address/0x644611f32769aaecceadec6462c9495b23b40520">0x644611f32769aaecceadec6462c9495b23b40520</a>
              </div>
              <div>
                <span>Engine:</span>
                <a target="_blank" href="https://goerli.etherscan.io/address/0xe7c9Cb6D966f76f3B5142167088927Bf34966a1f">0xe7c9Cb6D966f76f3B5142167088927Bf34966a1f</a>
              </div> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
